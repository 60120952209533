import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { computed, ref } from "vue";

const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

export interface IStep {
  id: number;
  descrizione: string;
  descrizione_en: string;
  is_fully_optional: boolean;
}

export interface IPagination {
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}
export interface ISetsHeader {
  after_kpi_number: number;
  message: string;
  message_en: string;
}

export interface ISurveyModule {
  stepList: Array<IStep>;
  setsHeaderList: Array<ISetsHeader>;
}

@Module
export default class SurveyModule extends VuexModule implements ISurveyModule {
  surveyErrors = [];
  surveyWarnings = [];
  stepList = [] as Array<IStep>;
  setsHeaderList = [] as Array<ISetsHeader>;
  surveyInputList: any = [];
  surveyFilteredInputList: any = [];
  currentInputListByStep: any = [];
  surveyStructureList = [];
  active_step_id= 1;
  /**
   * Get  step
   * @returns Array<IStep>
   */
  get getSurveyStepList(): Array<IStep> {
    return this.stepList;
  }

  /**
   * Get  set header list
   * @returns Array<ISetsHeader>
   */
  get getSurveySetsHeaderList(): Array<ISetsHeader> {
    return this.setsHeaderList;
  }

  /**
   * Get  inputList
   * @returns Pagination
   */
  get getSurveyInputList(): Array<any> {
    return this.surveyInputList;
  }

  /**
   * Get  inputList
   * @returns Pagination
   */
  get getSurveyStructureList(): Array<any> {
    return this.surveyStructureList;
  }

  /**
   * Get  inputList
   * @returns Pagination
   */
  get getFilteredSurveyInputList(): Array<any> {
    return this.surveyFilteredInputList;
  }

  /**
   * Get  primary menu
   * @returns surveyErrors
   */
  get getSurveyErrors(): Array<any> {
    return this.surveyErrors;
  }

  /**
   * Get  primary menu
   * @returns surveyErrors
   */
  get getSurveyWarnings(): Array<any> {
    return this.surveyWarnings;
  }

  /**
   * Get  Active Survey Step id
   * @returns surveyErrors
   */
  get getActiveStepId(): number {
    return this.active_step_id;
  }

  @Mutation
  [Mutations.SET_SURVEY_ERROR](error) {
    this.surveyErrors = error;
  }

  @Mutation
  [Mutations.SET_SURVEY_WARNING](warning) {
    this.surveyWarnings = warning;
  }

  @Mutation
  [Mutations.SET_SURVEY_SETS_HEADER_LIST](data) {
    this.setsHeaderList = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_STEP_LIST](data) {
    this.stepList = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_INPUT_LIST](data) {
    this.surveyInputList = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_STRUCTURE_LIST](data) {
    this.surveyStructureList = data;
  }

  @Mutation
  [Mutations.SET_FILTERED_SURVEY_INPUT_LIST](data) {
    this.surveyFilteredInputList = data;
  }

  @Mutation
  [Mutations.SET_ACTIVE_SURVEY_STEP_ID](data) {
    this.active_step_id = data;
  }

  @Action
  [Actions.REQ_SURVEY_STEP_LIST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("set", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_STEP_LIST, data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_SETS_HEADER_LIST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("sets-header", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_SETS_HEADER_LIST, data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_INPUT_LIST](user_id = "") {
    const year = isAdmin.value ? localStorage.getItem("to_year_admin") : localStorage.getItem("to_year");
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`input/${year}/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_INPUT_LIST, data.data);
          this.context.commit(Mutations.SET_FILTERED_SURVEY_INPUT_LIST, data.data);
          this.context.commit(Mutations.SET_ACTIVE_SURVEY_STEP_ID, data.active_set_id);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_STRUCTURE_LIST]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`survey-structure`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_STRUCTURE_LIST, data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.PUT_SURVEY_INPUT_LIST](payload) {
    const year =
      payload.id_year !== "" && payload.id_year !== undefined
        ? payload.id_year
        : store.getters.getCompanyYearList.current_yearly_period;
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`input/${year}/${payload.id}?force=${String(Boolean(payload.force))}`, payload.data)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            Mutations.SET_SURVEY_ERROR,
            err.response.data.error
          );
          reject();
        });
    });
  }

  @Action
  [Actions.CHECK_SURVEY_INPUT_LIST](payload) {
    const year =
      payload.id_year !== "" && payload.id_year !== undefined
        ? payload.id_year
        : store.getters.getCompanyYearList.current_yearly_period;
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`input/check/${year}/${payload.id}`, payload.data)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          this.context.commit(
            Mutations.SET_SURVEY_WARNING,
            err.response.data.warnings
          );
          reject();
        });
    });
  }

  @Action
  [Actions.PURGE_SURVEY_WARNING]() {
    this.context.commit(Mutations.SET_SURVEY_WARNING, []);
  }

  @Action
  [Actions.DEL_AGENT]() {
    this.context.commit(Mutations.SET_AGENT_LIST, []);
  }

  @Action
  [Actions.REMOVE_SURVEY_ERROR]() {
    this.context.commit(Mutations.SET_SURVEY_ERROR, []);
  }

  @Action
  [Actions.PUT_FILTERED_SURVEY_INPUT_LIST](payload) {
    this.context.commit(Mutations.SET_FILTERED_SURVEY_INPUT_LIST, payload);
  }
}
