import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface IRanking {
  id: string,
  attributes: string
}

export interface IScoreResult {
  id: string,
  total_score: number
}

export interface ISurveyPlusReportModule {
  rankings: Array<IRanking>;
  scoreResult: IScoreResult;
}

@Module
export default class SurveyPlusReportModule extends VuexModule implements ISurveyPlusReportModule {

  rankings = [] as Array<IRanking>;
  scoreResult = {} as IScoreResult;
  compareResult = {};
  compareDetailResult = {};

  /**
  * Get raking type list
  * @returns Array<IRanking>
  */
  get getSurveyPlusRankings(): Array<IRanking> {
    return this.rankings;
  }

  /**
  * Get result
  * @returns IScoreResult
  */
   get getSurveyPlusScoreResult(): IScoreResult {
    return this.scoreResult;
   }
  
  /**
  * Get Compare result
  * @returns
  */
   get getSurveyPlusCompareResult() {
    return this.compareResult;
   }
  
  /**
  * Get Compare Detail result
  * @returns
  */
   get getSurveyPlusCompareDetailResult() {
    return this.compareDetailResult;
   }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_RANKINGS](data: Array<IRanking>) {
    this.rankings = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_SCORE_RESULT](data: IScoreResult) {
    this.scoreResult = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_COMPARE_RESULT](data) {
    this.compareResult = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_COMPARE_DETAIL_RESULT](data) {
    this.compareDetailResult = data;
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_RANKINGS]() {
    ApiService.configRequestForStrapi();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("rankings?sort[0]=order", "")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_RANKINGS, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_SCORE_RESULT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/survey-plus-result?company_id=${payload.company_id}&year=${payload.year}`, "")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_SCORE_RESULT, data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_COMPARE_REPORT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`survey-plus-result/compare/${payload.user_id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_COMPARE_RESULT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_COMPARE_DETAIL_REPORT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`survey-plus-result/compare-detail/${payload.user_id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_COMPARE_DETAIL_RESULT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }
}
