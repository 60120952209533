import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {  IPagination } from "@/store/modules/divere/UserModule";
import  CompanyYearService  from "@/core/services/CompanyYearService";
import { computed, ref } from "vue";
import store from "@/store";

const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

export interface ICompany{
  denom : string;
  azienda_id: number;
  p_iva: string;
}

export interface ICompanyModule {
  companyList: Array<ICompany>;
  companyProfile :  ICompany;
  paginationCompanyList : IPagination;
}

export interface ICompanyYear {
  current_yearly_period: number;
  last_yearly_period: number;
  earliest_yearly_period:  number;
  company_year_option: number [];
  company_year_iii_is_completed: Record<number, boolean>;
  available_years: number [];
}


export interface ICompanyYearlyParams {
  company_type: Record<string, unknown>;
  azienda_yearly_parameters:  Record<string, unknown>;
  settore: Record<string, unknown>;
  fatturato: Record<string, unknown> ;
  employees_range: Record<string, unknown>;
  year: number;
  latest: boolean;
}

export interface ICompanyPerYearParameters {
  companyPerYearParameters: any;
}

@Module
export default class CompanyModule extends VuexModule implements ICompanyModule{

  companyErrors = [];
  companyProfile = CompanyYearService.getCompanyProfile() as ICompany;
  companyList = [] as Array<ICompany>;
  paginationCompanyList  = {} as IPagination;
  companyYearList = CompanyYearService.getCompanyYearList() as ICompanyYear;
  companyYearlyParams = null as ICompanyYearlyParams | null;

  companyPerYearParameters = null as ICompanyPerYearParameters | null;

  /**
   * Get  company list
   * @returns Array<User> 
  */
  get getCompanyList(): Array<ICompany> {
    return this.companyList;
  }

  /**
   * Get  company list
   * @returns ICompanyYearService
  */
  get getCompanyYearList(): ICompanyYear {
    return this.companyYearList;
  }
  
  /**
   * Get  primary menu
   * @returns userErrors
  */
  get getCompanyErrors(): Array<string> {
    return this.companyErrors;
  }


    /**
   * Get  primary menu
   * @returns companyProfile
  */
  get getCompanyProfile(): ICompany{
    return this.companyProfile;
  }

  /**
   * 
   * @param data 
   */
  get getCompanyYearlyParams(): ICompanyYearlyParams | null{
    return this.companyYearlyParams;
  }

  /**
   * Get  primary menu
   * @returns companyPerYearParameters
  */
  get getCompanyPerYearParamters(): ICompanyPerYearParameters | null {
    return this.companyPerYearParameters;
  }

  @Mutation
  [Mutations.SET_COMPANY](data) {
    this.companyProfile= data;
    CompanyYearService.saveCompanyProfile(data)
  }

  @Mutation
  [Mutations.SET_COMPANY_ERROR](error) {
    this.companyErrors = error;
  }

  @Mutation
  [Mutations.SET_COMPANY_LIST](data) {
    this.companyList= data;
  }
  
  @Mutation
  [Mutations.SET_COMPANY_LIST_YEAR](data) {
    this.companyYearList = data ;
    CompanyYearService.saveCompanyYearList(data);
  }

  @Mutation
  [Mutations.SET_CURRENT_YEARLY_PERIOD](current_yearly_period) {
    this.companyYearList.current_yearly_period = parseInt(current_yearly_period);
    CompanyYearService.saveCompanyYearList(this.companyYearList);
  }

  @Mutation
  [Mutations.SET_COMPANY_LIST_PAGINATION](data) {
    this.paginationCompanyList = data;
  }

  @Mutation
  [Mutations.SET_COMPANY_YEARLY_PARAMS](company_yearly_params) {
    this.companyYearlyParams = company_yearly_params;
  }

  @Mutation
  [Mutations.SET_COMPANY_PER_YEAR_PARAMETERS](data) {
    this.companyPerYearParameters = data;
  }

  @Action
  [Actions.REQ_COMPANY_LIST](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende", payload)
        .then(({ data:{data} }) => {
          this.context.commit(Mutations.SET_COMPANY_LIST, data.results);
          this.context.commit(Mutations.SET_COMPANY_LIST_PAGINATION, data.pagination);
          resolve();
        })
        .catch(({ data: {data} }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPANY](id) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende/show/", id)
        .then(({ data: { company } }) => {
          this.context.commit(Mutations.SET_COMPANY, company);
          resolve();
        })
        .catch(({ data: { errors } }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPANY_LIST_YEAR](company_id = "") {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende/inityears/", company_id)
        .then(({ data: {init_years} }) => {
          this.context.commit(Mutations.SET_COMPANY_LIST_YEAR, init_years);
          resolve();
        })
        .catch(({ errors  }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPANY_YEARLY_PARAMS](company_id = "") {
    this.context.commit(Mutations.SET_COMPANY_YEARLY_PARAMS, null);
    ApiService.setHeader();
    const current_yearly_period = isAdmin.value ? localStorage.getItem("to_year_admin") : this.getCompanyYearList.current_yearly_period;
    if (!current_yearly_period) return;
    const lang = localStorage.getItem("lang");
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende/yearly-parameters/" + current_yearly_period + "/" + lang + "/", company_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPANY_YEARLY_PARAMS, data);
          resolve(data);
        })
        .catch(({ errors }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPANY_EMPLOYEE_RANGE_LIST]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("employee-range")
        .then(({ data }) => {
          return data;
        })
        .catch(({ errors  }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }
  @Action
  [Actions.REQ_COMPANY_INDUSTRIAL_SECTOR_LIST]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("settori")
        .then(({ data }) => {
          return data;
        })
        .catch(({ errors  }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPANY_REVENUE_RANGE]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("fatturati")
        .then(({ data }) => {
          return data;
        })
        .catch(({ errors  }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DEL_COMPANY]() {
    this.context.commit(Mutations.SET_COMPANY_LIST, []);
  }
  
  @Action
  [Actions.CHANGE_CURRENT_YEARLY_PERIOD](year) {
    this.context.commit(Mutations.SET_CURRENT_YEARLY_PERIOD, year);
  }
  
  @Action
  [Actions.REQ_COMPANY_PER_YEAR_PARAMETERS](company_id = "") {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende/peryear/", company_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPANY_PER_YEAR_PARAMETERS, data);
          resolve();
        })
        .catch(({ errors  }) => {
          this.context.commit(Mutations.SET_COMPANY_ERROR,errors);
          reject();
        });
    });
  }
  
}