import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {  IUser } from "@/store/modules/valored/UserModule";
import ApiService from "@/core/services/ApiService";
import { errorMessage } from "@/core/services/AlertService";

export interface IAdminInfo {
  selectedUser: IUser | null;
}

@Module
export default class AdminModule extends VuexModule implements IAdminInfo {

  selectedUser = null as IUser |  null;

  /**
   * Get current user object
   * @returns IUser
   */
  get getSelectedUser(): IUser | null {
    return this.selectedUser;
  }

  @Mutation
  [Mutations.SAVE_SELECTED_USER](data) {
    this.selectedUser = data;
  }

  @Action
  [Actions.SET_SELECTED_USER](payload) {
    this.context.commit(Mutations.SAVE_SELECTED_USER, payload);
  }

  @Action
  [Actions.PURGE_SELECTED_USER]() {
    this.context.commit(Mutations.SAVE_SELECTED_USER, null);
  }

  @Action
  [Actions.UPDATE_PASSWORD_ADMIN](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/admin/update-password", payload)
        .then(() => {
          resolve();
        })
        .catch(({response}) => {
          errorMessage(response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PROFILE_ADMIN](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/admin/update-account", payload)
        .then(() => {
          resolve();
        })
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPANY_PROFILE_ADMIN](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`/admin/update-company`, payload)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_USER_ACTIVATION_ADMIN](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/admin/users/activate/${payload}`)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_USER_DEACTIVATION_ADMIN](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/admin/users/activate/${payload}?action=deactivate`)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.REFUSE_USER_INVITATION](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/admin/users/refuse/${payload}`)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.DEL_COMPANY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`/aziende/${payload}`)
        .then(() => {
          resolve();
        })  
        .catch(() => {
          reject();
        });
    });
  }
}
