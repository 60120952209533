import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/valored/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import MenuModule from "./modules/valored/MenuModule";
import UserModule from "./modules/valored/UserModule";
import CompanyModule from "./modules/valored/CompanyModule";
import IIIModule from "./modules/valored/IIIModule";
import SurveyModule from "./modules/valored/SurveyModule";
import SurveyPlusModule from "./modules/valored/SurveyPlusModule";
import ReportModule from "./modules/valored/ReportModule";
import SurveyPlusReportModule from "./modules/valored/SurveyPlusReportModule";
import AdminModule from "./modules/valored/AdminModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    MenuModule,
    UserModule,
    CompanyModule,
    IIIModule,
    SurveyModule,
    SurveyPlusModule,
    ReportModule,
    SurveyPlusReportModule,
    AdminModule
  },
});

export default store;
