import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { computed, ref } from "vue";
import store from "@/store";

const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

export interface Attributes {
  uid: string;
  description: string;
  title: string;
  position: number;
  description_en: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  bullets: Array<any>;
}

export interface IStep {
  id: number;
  attributes: Attributes;
}

export interface IQuestion {
  id: number;
}

export interface ISurveyPlusModule {
  stepList: Array<IStep>;
}

@Module
export default class SurveyModule extends VuexModule implements ISurveyPlusModule {
  surveyPlusErros = [];
  stepList = [] as Array<IStep>;
  questionList = [] as Array<IQuestion>;
  filteredQuestionList = [] as Array<IQuestion>;
  serveyResult = {};
  firstQuestion = {} as IQuestion;
  bullets = [] as Array<any>;

  /**
   * Get  step list
   * @returns Array<IStep>
   */
  get getSurveyPlusStepList(): Array<IStep> {
    return this.stepList;
  }

  /**
   * Get  question list
   * @returns Array<IQuestion>
   */
   get getSurveyPlusQuestionList(): Array<IQuestion> {
    return this.questionList;
   }
  
  get getSurveyPlusFirstQuestion(): IQuestion {
    return this.firstQuestion;
  }
  
  /**
   * Get  survey result
   * @returns Object
   */
  get getSurveyPlusResult() {
    return this.serveyResult;
  }

  /**
   * Get primary menu
   * @returns bullets
  */
  get getSurveyPlusBullets(): Array<any> {
    return this.bullets;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_ERROR](error) {
    this.surveyPlusErros = error;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_STEP_LIST](data) {
    this.stepList = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_QUESTION_LIST](data) {
    this.questionList = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_FIRST_QUESTION](data) {
    this.firstQuestion = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_RESULT](data) {
    this.serveyResult = data;
  }

  @Mutation
  [Mutations.SET_SURVEY_PLUS_BULLETS](data) {
    this.bullets = data;
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_STEP_LIST](payload) {
    ApiService.configRequestForStrapi();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("areas", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_STEP_LIST, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_FIRST_QUESTION]() {
    const url = `surveys?populate=*&filters[$and][0][uid][$eq]=D0`
    ApiService.configRequestForStrapi();
    this.context.commit(Mutations.SET_SURVEY_PLUS_FIRST_QUESTION, {});
    return new Promise<void>((resolve, reject) => {
      ApiService.get(url, '')
        .then(({ data }) => {
          console.log(data);
          this.context.commit(Mutations.SET_SURVEY_PLUS_FIRST_QUESTION, data['data'][0]);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_QUESTION_LIST](payload) {
    const url = payload ? `surveys?populate=*&pagination[pageSize]=100&sort=position:ASC&filters[$and][0][company_size][id][$eq]=${payload}` : `surveys?populate=*&pagination[pageSize]=100&sort=position:ASC`
    ApiService.configRequestForStrapi();
    this.context.commit(Mutations.SET_SURVEY_PLUS_QUESTION_LIST, []);
    return new Promise<void>((resolve, reject) => {
      ApiService.get(url, '')
        .then(({ data }) => {
          data.data.forEach(question => {
            question.attributes.answers.data.sort((a, b) => (a.attributes.position > b.attributes.position) ? 1 : -1)
          })
          
          this.context.commit(Mutations.SET_SURVEY_PLUS_QUESTION_LIST, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_RESULT](payload) {
    const year = isAdmin.value ? localStorage.getItem("to_year_admin") : store.getters.getCompanyYearList.current_yearly_period;
    const user_id = payload.user_id ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`survey-plus-answer/${year}/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_RESULT, data);
          resolve(data);
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.PUT_SURVEY_PLUS_ANSWERS](payload) {
    const year = isAdmin.value ? localStorage.getItem("to_year_admin") : store.getters.getCompanyYearList.current_yearly_period;
    console.log(`survey-plus-answer/${year}/${payload.user_id}`, payload);
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`survey-plus-answer/${year}/${payload.user_id}`, payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ data: { data } }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_SURVEY_PLUS_BULLETS](payload) {
    if (!payload.year) return;
    const user_id = (payload.user_id) ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`/survey-plus/me/bullets/${payload.year}/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_SURVEY_PLUS_BULLETS, data);
          resolve();
        })
        .catch(({ data:{errors} }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SURVEY_PLUS]() {
    const year = localStorage.getItem("to_year");
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`/survey-plus/me/${year}/`)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ data:{errors} }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DELETE_SURVEY_PLUS_ADMIN](user_id) {
    const year = localStorage.getItem("to_year_admin");
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.delete(`/survey-plus/${user_id}/${year}/`)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ data:{errors} }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

}
