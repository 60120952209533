import { createI18n } from "vue-i18n";
import it_messages from "./translations/it_messages";
import en_messages from "./translations/en_messages";

const messages = {
  en: en_messages,
  it: it_messages
};

const i18n = createI18n({
  legacy: false,
  locale: window.navigator.language.slice(0, 2),
  fallbackLocale: "en",
  globalInjection: true,
  messages,
});

if (!localStorage.getItem("lang")) {
  const localeLanguage = window.navigator.language.slice(0, 2);
  localStorage.setItem(
    "lang", 
    (
      localeLanguage == "en" || localeLanguage == "it"
      ? localeLanguage
      : "en"
    )
  );
}

export default i18n;
