enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  GET_GLOBAL_CONFIG = "getGlobalConfig",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  REQ_PRIMARY_MENU = "reqPrimaryMenu",
  REQ_AGENT_LIST = "reqAgentList",
  DEL_AGENT = "deleteAgent",
  REQ_USER_LIST = "reqUserList",
  DEL_USER = "deleteUser",
  REQ_COMPANY = "reqCompany",
  REQ_COMPANY_LIST = "reqCompanyList",
  DEL_COMPANY = "deleteCompany",
  REQ_III_LIST = "reqIIIList",
  REQ_III = "reqIII",
  DEL_III = "delIII",
  REQ_COMPANY_LIST_YEAR = "reqCompanyListYer",
  CHANGE_CURRENT_YEARLY_PERIOD = "changeCurrentYearlyPeriod",
  REQ_COMPANY_YEARLY_PARAMS = "reqCompanyYearlyParams",
  REQ_COMPANY_INDUSTRIAL_SECTOR_LIST = "reqCompanyIndustrialSectorList",
  REQ_COMPANY_EMPLOYEE_RANGE_LIST = "reqCompanyEmployeeRangeList",
  REQ_COMPANY_REVENUE_RANGE = "reqCompanyRevenueRangeList",
  REQ_SURVEY_STEP_LIST = "reqSurveyStepList",
  REQ_SURVEY_SETS_HEADER_LIST = "reqSurveySetsHeaderList",
  REQ_SURVEY_INPUT_LIST = "reqSurveyInputList",
  PUT_SURVEY_INPUT_LIST = "putSurveyInputList",
  REMOVE_SURVEY_ERROR = "removeSurveyError",
  PUT_FILTERED_SURVEY_INPUT_LIST = "putFilteredSurveyInputList",
  CHECK_SURVEY_INPUT_LIST = "checkSurveyInputList",
  REQ_AVERAGE_REPORT = "reqAverageReport",
  REQ_DETAIL_REPORT= "reqDetailReport",
  REQ_COMPARE_REPORT="reqCompareReport",
  REQ_INITIAL_COMPARE_SELECT_OPTIONS= "reqInitialCompareSelectOptions",
  REQ_AVERAGE_REPORT_LIST="reqAverageReportList",
  CHANGE_ASIDE_MENU="changeAsideMenu",
  EMAIL_VERIFY="emailVerify",
  EMAIL_RESEND="emailResend",
  RESET_PASSWORD="RESET_PASSWORD",
  UPDATE_PASSWORD="updatepassword",
  UPDATE_EMAIL="updateEmail",
  UPDATE_USER_PROFILE="updateUserProfile",
  UPDATE_COMPANY_PROFILE = "updateCompanyProfile",
  CREATE_COMPANY_PROFILE = "createCompanyProfile",
  SET_SELECTED_USER="setSelectedUser",
  PURGE_SELECTED_USER="PURGE_SELECTED_USER",
  UPDATE_PASSWORD_ADMIN="updatePasswordAdmin",
  UPDATE_PROFILE_ADMIN="updateProfileAdmin",
  UPDATE_COMPANY_PROFILE_ADMIN = "updateCompanyProfileAdmin",
  RESET_USER_ACTIVATION_ADMIN = "resetUserActivationAdmin",
  RESET_USER_DEACTIVATION_ADMIN = "resetUserDeactivationAdmin",
  REQ_SURVEY_STRUCTURE_LIST = "reqSurveyStructureList",
  REQ_SURVEY_PLUS_STEP_LIST = "reqSurveyPlusStepList",
  REQ_SURVEY_PLUS_FIRST_QUESTION = "reqSurveyPlusFirstQuestion",
  REQ_SURVEY_PLUS_QUESTION_LIST = "reqSurveyPlusQuestionList",
  PUT_SURVEY_PLUS_FILTERED_QUESTION_LIST = "putSurveyPlusFilteredQuestionList",
  REQ_SURVEY_PLUS_RESULT = "reqSurveyPlusResult",
  PUT_SURVEY_PLUS_ANSWERS = "putSurveyPlusAnswers",
  REQ_SURVEY_PLUS_RANKINGS = "reqSurveyPlusRankings",
  REQ_SURVEY_PLUS_SCORE_RESULT = "reqSurveyPlusScoreResult",
  REQ_SURVEY_PLUS_COMPARE_REPORT = "reqSurveyPlusCompareReport",
  REQ_SURVEY_PLUS_COMPARE_DETAIL_REPORT = "reqSurveyPlusCompareDetailReport",
  PURGE_SURVEY_WARNING="purgeSurveyWarning",
  REQ_GENERATION_MIX_REPORT="reqGenerationMixReport",
  REQ_GENERATION_MIX_TABLE="reqGenerationMixTable",
  REFUSE_USER_INVITATION="refuseUserInvitation",

  REQ_III_IS_PENDING="reqIIIIsPending",
  REQ_COMPANY_PER_YEAR_PARAMETERS="reqCompanyPerYearParameters",
  REQ_III_BULLETS="reqIIIBullets",
  REQ_SURVEY_PLUS_BULLETS="reqSurveyPlusBullets",
  DELETE_SURVEY_PLUS="deleteSurveyPlus",
  DELETE_SURVEY_PLUS_ADMIN="deleteSurveyPlusAdmin",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_GLOBAL_CONFIG = "setGlobalConfig",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_COMPANY = "setCompany",
  SET_COMPANY_LIST = "setCompanyList",
  SET_COMPANY_ERROR = "setCompanyError",
  SET_COMPANY_LIST_PAGINATION = "setCompanyListPagination",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_USER_ERROR = "setUserError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_PRIMARY_MENU = "setPrimaryMenu",
  SET_AGENT_LIST = "setAgentList",
  SET_USER_LIST_PAGINATION = "setUserListPagination",
  SET_EXT_PROFILE = "setExtProfile",
  SET_III = "setIII",
  SET_III_ERROR = "setIIIError",
  SET_III_LIST = "setIIIList",
  SET_COMPANY_LIST_YEAR = "setCompanyListYear",
  SET_COMPANY_LIST_YEAR_ERROR = "setCompanyListYearError",
  SET_CURRENT_YEARLY_PERIOD = "setCurrentYearlyPeriod",
  SET_COMPANY_YEARLY_PARAMS = "setCompanyYearlyParams",
  SET_COMPANY_INDUSTRIAL_SECTOR_LIST = "setCompanyIndustrialSectorList",
  SET_COMPANY_EMPLOYEE_RANGE_LIST = "setCompanyEmployeeRangeList",
  SET_COMPANY_REVENUE_RANGE = "setCompanyRevenueRangeList",
  SET_SURVEY_ERROR = "setSurveyError",
  SET_SURVEY_WARNING = "setSurveyWarning",
  SET_SURVEY_STEP_LIST = "setSurveyStepList",
  SET_SURVEY_INPUT_LIST = "setSurveyInputList",
  SET_FILTERED_SURVEY_INPUT_LIST = "setFilteredSurveyInputList",
  SET_SURVEY_SETS_HEADER_LIST = "setSurveySetsHeaderList",
  SET_AVERAGE_REPORT = "setAvercageReport",
  SET_DETAIL_REPORT="setDetailReport",
  SET_COMPARE_SELECT_OTPIONS= "setCompareSelectOptions",
  SET_COMPARE_REPORT="setCompareReport",
  SET_AVERAGE_REPORT_LIST="setAverageReportList",
  SET_ASIDE_MENU="setAsideMenu",
  SET_SETTORI_LIST="setSettorilist",
  SAVE_SELECTED_USER = "saveSelectedUser",
  SET_YEARS_LIST="setYearsList",
  SET_SURVEY_STRUCTURE_LIST = "setSurveyStructureList",
  SET_SURVEY_PLUS_ERROR = "setSurveyPlusError",
  SET_SURVEY_PLUS_STEP_LIST = "setSurveyPlusStepList",
  SET_SURVEY_PLUS_QUESTION_LIST = "setSurveyPlusQuestionList",
  SET_SURVEY_PLUS_FIRST_QUESTION = "setSurveyPlusFirstQuestion",
  SET_SURVEY_PLUS_RESULT = "setSurveyPlusResult",
  SET_SURVEY_PLUS_RANKINGS = "setSurveyPlusRankings",
  SET_SURVEY_PLUS_SCORE_RESULT = "setSurveyPlusScoreResult",
  SET_SURVEY_PLUS_COMPARE_RESULT = "setSurveyPlusCompareResult",
  SET_SURVEY_PLUS_COMPARE_DETAIL_RESULT = "setSurveyPlusCompareDetailResult",
  SET_GENERATION_MIX_REPORT="setGenerationMixReport",
  SET_GENERATION_MIX_TABLE="setGenerationMixTable",
  SET_ACTIVE_SURVEY_STEP_ID="setActiveSurveyStepId",

  SET_III_IS_PENDING="setIIIIsPending",
  SET_COMPANY_PER_YEAR_PARAMETERS="setCompanyPerYearParameters",
  SET_III_BULLETS="setIIIBullets",
  SET_SURVEY_PLUS_BULLETS="setSurveyPlusBullets"
}

enum InputTypes {
  // mutation types
  INTEGER_GENDER_FIELD = "IntegerGenderField",
  SELECT_YES_NO_FIELD = "SelectYesNoField",
  ROLE_GENDER_FIELD = "RoleGenderField",
  TEXT_AREA_FIELD = "TextAreaField",
  FLOAT_GENDER_FIELD = "FloatGenderField",
  PERCENTAGE_FIELD = "PercentageField",

  VALUE_YES_FIELD = "YES",
  VALUE_NO_FIELD = "NO",
  VALUE_WOMAN_FIELD = "WOMAN",
  VALUE_MAN_FIELD = "MAN",
  INTEGER_TOTAL_SUB_ID = 0,
  INTEGER_WOMEN_SUB_ID = 1,
  INTEGER_MEN_SUB_ID = 2,
  SELECT_YES_NO_WOMEN_SUB_ID = 0, // changed
  SELECT_YES_NO_MEN_SUB_ID = 1, // changed
  FLOAT_WOMEN_SUB_ID = 0,
  FLOAT_MEN_SUB_ID = 1
}

export { Actions, Mutations, InputTypes };
