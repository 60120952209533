
import {  ICompanyYear, ICompany } from "@/store/modules/valored/CompanyModule";
const COMPANY_YEAR_LIST = "comapny_year_list" as string;
const COMPANY_PROFILE = "company_profile" as string;

/**
 * @description get companyYearList form localStorage
 */
export const getCompanyYearList = (): ICompanyYear => {
  const companyYearListItem =  window.localStorage.getItem(COMPANY_YEAR_LIST);
  if(companyYearListItem && typeof companyYearListItem === "string"){
    return JSON.parse(companyYearListItem);
  }
  return {} as ICompanyYear;
};

/**
 * @description save companyYearList into localStorage
 * @param companyYearList: string
 */
export const saveCompanyYearList = (companyYearList: ICompanyYear): void => {
  window.localStorage.setItem(COMPANY_YEAR_LIST, JSON.stringify(companyYearList));
};

/**
 * @description remove companyYearList form localStorage
 */
export const destroyCompanyYearList = (): void => {
  window.localStorage.removeItem(COMPANY_YEAR_LIST);
};

/**
 * @description get COMPANY_PROFILE form localStorage
 */
 export const getCompanyProfile = (): ICompany => {
  const companyProfileItem =  window.localStorage.getItem(COMPANY_PROFILE);
  if(companyProfileItem && typeof companyProfileItem === "string"){
    return JSON.parse(companyProfileItem);
  }
  return {} as ICompany;
};

/**
 * @description save COMPANY_PROFILE into localStorage
 * @param last_yearly_period: string
 */
export const saveCompanyProfile = (company_profile: string): void => {
  window.localStorage.setItem(COMPANY_PROFILE, JSON.stringify(company_profile));
};

/**
 * @description remove COMPANY_PROFILE form localStorage
 */
export const destroyCompanyProfile = (): void => {
  window.localStorage.removeItem(COMPANY_PROFILE);
};



export default {
  getCompanyYearList,
  saveCompanyYearList,
  destroyCompanyYearList,
  getCompanyProfile,
  saveCompanyProfile,
  destroyCompanyProfile
};
