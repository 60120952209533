import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import  CompanyYearService  from "@/core/services/CompanyYearService";
import AuthService from "@/core/services/AuthService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {  IUser } from "@/store/modules/valored/UserModule";
import { errorMessage } from "@/core/services/AlertService";
import store from "@/store";
import { computed, ref } from "vue";

export interface IUserAuthInfo {
  errors: Array<string>;
  user: IUser;
  isAuthenticated: boolean;
  globalConf: unknown;
}

const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

@Module
export default class AuthModule extends VuexModule implements IUserAuthInfo {
  errors = [];
  user = AuthService.getUser() as IUser;
  isAuthenticated = !!JwtService.getToken();
  globalConf = {};

  /**
   * Get current user object
   * @returns IUser
   */
  get currentUser(): IUser {
    return this.user;
  }

  /**
   * Check the current use is Admin
   * @returns IUser
   */
  get isAdmin(): boolean {
    return this.user? (this.user.user_account_type == 7 ? true: false) : false;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get getGlobalConf(): unknown {
    return this.globalConf;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.errors = [];
    JwtService.saveToken(data.token);
    AuthService.saveUser(data.user);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
    AuthService.saveUser(user);
  }


  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.errors = [];
    JwtService.destroyToken();
    CompanyYearService.destroyCompanyProfile();
    CompanyYearService.destroyCompanyYearList();
    AuthService.destroyUser();
  }

  @Mutation
  [Mutations.SET_GLOBAL_CONFIG](data) {
    this.globalConf = data;
  }


  @Action
  [Actions.LOGIN](credentials) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials)
        .then(( data ) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          resolve();
        })
        .catch((data ) => {
          this.context.commit(Mutations.SET_ERROR, data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    ApiService.setHeader();
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("register", credentials)
        .then(({ data }) => {
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.EMAIL_VERIFY](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/email/verify/" + payload.user_id + "/" +  payload.user_hash)
        .then(( data ) => {
          resolve(data.data);
        })
        .catch((data ) => {
          this.context.commit(Mutations.SET_ERROR, data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.EMAIL_RESEND](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/email/verify/resend", payload)
        .then(() => {
          resolve();
        })
        .catch((data ) => {
          this.context.commit(Mutations.SET_ERROR, data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(() => {
          resolve();
        })
        .catch(({response}) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("reset_password", payload)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("verify")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_GLOBAL_CONFIG]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("get-global-config")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GLOBAL_CONFIG, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PASSWORD](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_password", payload)
        .then(() => {
          resolve();
        })
        .catch(({response}) => {
          errorMessage(response.data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_EMAIL](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_email", payload)
        .then(() => {
          resolve();
        })
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_USER_PROFILE](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.put("users", payload)
        .then(({ data }) => {
          if (!isAdmin.value) this.context.commit(Mutations.SET_USER, data);
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_COMPANY_PROFILE](payload) {
    ApiService.setHeader();
    const year = store.getters.getCompanyYearList.current_yearly_period;
    return new Promise<void>((resolve, reject) => {
      ApiService.put(`aziende/${year}`, payload)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }

  @Action
  [Actions.CREATE_COMPANY_PROFILE](payload) {
    const year = isAdmin.value ? localStorage.getItem("to_year_admin") : localStorage.getItem("to_year");
    const user_id = isAdmin.value ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`aziende/${year}/${user_id}`, payload)
        .then(() => {
          resolve();
        })  
        .catch(({response}) => {
          errorMessage(response.data.message);
          reject();
        });
    });
  }
}
