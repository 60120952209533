import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {  IPagination } from "@/store/modules/divere/UserModule";
import store from "@/store";
import { computed, ref } from "vue";

const isAdmin = ref(computed(() => store.getters.isAdmin as boolean));

export interface IIIInterface {
  iii: any 
  time: string
  id_azienda: number
  id_year: number
  isIIIPending: any
  bullets: any
  // TODO: move isSurveyPlusPending to another interface.
  isSurveyPlusPending: any
}

export interface IIIIModule {
  iii : IIIInterface | null;
  isIIIPending: Array<IIIInterface>;
  bullets: Array<IIIInterface>;
  // TODO: move isSurveyPlusPending to another interface.
  isSurveyPlusPending: Array<IIIInterface>;
}

@Module
export default class IIIModule extends VuexModule implements IIIIModule{

  iiiErrors = [];
  iii = null as IIIInterface | null;
  iiiList = [] as Array<IIIInterface>;
  isIIIPending = [] as Array<IIIInterface>;
  bullets = [] as Array<IIIInterface>;

  // TODO: move isSurveyPlusPending to another interface.
  isSurveyPlusPending = [] as Array<IIIInterface>;

  /**
   * Get  iii list
   * @returns Array<User> 
  */
  get getIIIList(): Array<IIIInterface> {
    return this.iiiList;
  }


  /**
   * Get  primary menu
   * @returns userErrors
  */
  get getIIIErrors(): Array<string> {
    return this.iiiErrors;
  }


  /**
   * Get  primary menu
   * @returns iii
  */
  get getIII(): IIIInterface | null{
    return this.iii
  }

  /**
   * Get primary menu
   * @returns isIIIPending
  */
  get getIsIIIPending(): Array<IIIInterface> {
    return this.isIIIPending;
  }

  /**
   * Get primary menu
   * @returns isSurveyPlusPending
  */
  // TODO: move getIsSurveyPlusPending to another interface.
  get getIsSurveyPlusPending(): Array<IIIInterface> {
    return this.isSurveyPlusPending;
  }

  /**
   * Get primary menu
   * @returns bullets
  */
  get getIIIBullets(): Array<IIIInterface> {
    return this.bullets;
  }

  @Mutation
  [Mutations.SET_III](data) {
    if(data.success) {
      this.iii = data;
      if(this.iii) 
      this.iii.iii = (parseFloat(data.iii)*100).toFixed(1)
    }else {
      this.iii= null;
    }
  }

  @Mutation
  [Mutations.SET_III_IS_PENDING](data) {
    if (data) {
      this.isIIIPending = data.is_iii_pending;
      this.isSurveyPlusPending = data.is_survey_plus_pending;
    } else {
      this.isIIIPending = [];
      this.isSurveyPlusPending = [];
    }
  }

  @Mutation
  [Mutations.SET_III_ERROR](error) {
    this.iiiErrors = error;
  }

  @Mutation
  [Mutations.SET_III_LIST](data) {
    this.iiiList= data;
  }

  @Mutation
  [Mutations.SET_III_BULLETS](data) {
    this.bullets = data;
  }

  @Action
  [Actions.REQ_III_LIST](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("aziende", payload)
        .then(({ data:{data} }) => {
          this.context.commit(Mutations.SET_III_LIST, data.results);
          resolve();
        })
        .catch(({ data:{data}  }) => {
          this.context.commit(Mutations.SET_III_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_III](payload) {
    if (!payload.year) return;
    const user_id = payload.user_id ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`iii/me/${payload.year}/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_III, data);
          resolve();
        })
        .catch(({ data:{errors}  }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_III_IS_PENDING](user_id = "") {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`iii/me/ispending/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_III_IS_PENDING, data);
          resolve();
        })
        .catch(({ data:{errors} }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_III_BULLETS](payload) {
    if (!payload.year) return;
    const user_id = (payload.user_id) ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`iii/me/bullets/${payload.year}/`, user_id)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_III_BULLETS, data);
          resolve();
        })
        .catch(({ data:{errors} }) => {
          this.context.commit(Mutations.SET_III_ERROR, errors);
          reject();
        });
    });
  }

  @Action
  [Actions.DEL_III]() {
    this.context.commit(Mutations.SET_III, []);
  }
}