import {  IUser } from "@/store/modules/valored/UserModule";
const ID_USER = "id_user" as string;

/**
 * @description get user form localStorage
 */
export const getUser = (): IUser | null  => {
  const userItem = window.localStorage.getItem(ID_USER); 
  if(userItem && typeof userItem === 'string') {
    return JSON.parse(userItem);
  }
  return null;
};

/**
 * @description save user into localStorage
 * @param user: IUser
 */
export const saveUser = (user: IUser): void => {
  window.localStorage.setItem(ID_USER, JSON.stringify(user));
};

/**
 * @description remove user form localStorage
 */
export const destroyUser = (): void => {
  window.localStorage.removeItem(ID_USER);
};

export default { getUser, saveUser, destroyUser };
