import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {  ICompanyYearlyParams } from "@/store/modules/valored/CompanyModule";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface IUser {
  id : number;
  email : string;
  ruolo_aziendale: string;
  user_name : string; 
  user_type : string;
  user_account_type : number;
  telefono: string;
  denom: string;
  yearlyParameters?: Array<ICompanyYearlyParams>;
}

export interface IPagination{
  page: number;
  pageSize: number;
  pageCount: number;
  total: number;
}

export interface IUserModule{
  agentList: Array<IUser>;
  paginationUserList: IPagination;
  yearsList: Array<number>
}



@Module
export default class UserModule extends VuexModule implements IUserModule{

  userErrors = [];
  extProfile = {} as IUser;
  paginationUserList = {} as IPagination;
  agentList = [] as Array<IUser>;
  inactiveAgentList = [] as Array<IUser>;
  settori_list = {};
  yearsList = [] as Array<number>;

  /**
   * Get  agent list
   * @returns Array<User> 
  */
  get getAgentList(): Array<IUser> {
    return this.agentList;
  }

  /**
   * Get  agent list
   * @returns Array<User> 
  */
  get getInactiveAgentList(): Array<IUser> {
    return this.inactiveAgentList;
  }

  /**
   * Get  agent list
   * @returns Array<User> 
  */
  get getSettoriList() {
    return this.settori_list;
  }

  /**
   * Get years list
   * @returns Array<User> 
  */
  get getYearsList(): Array<number> {
    return this.yearsList;
  }

  /**
   * Get  pagination
   * @returns Pagination
  */
  get getPaginationUserList(): IPagination {
    return this.paginationUserList;
  }

  /**
   * Get  primary menu
   * @returns userErrors
  */
  get getUserErrors(): Array<string> {
    return this.userErrors;
  }


  @Mutation
  [Mutations.SET_USER_ERROR](error) {
    this.userErrors = error;
  }

  @Mutation
  [Mutations.SET_AGENT_LIST](data) {
    this.agentList= data.user_list.aziende_approved;
    this.inactiveAgentList= data.user_list.aziende_not_approved;
  }

  @Mutation
  [Mutations.SET_YEARS_LIST](data) {
    this.yearsList= data;
  }

  @Mutation
  [Mutations.SET_SETTORI_LIST](data) {
    const temp = {};
    data.map(item => {
      temp[item.id] = {
        descrizione : item.descrizione,
        descrizione_en : item.descrizione_en
      }
    })
    this.settori_list = temp;
  }

  @Mutation
  [Mutations.SET_EXT_PROFILE](data) {
    this.extProfile= data;
  }

  @Mutation
  [Mutations.SET_USER_LIST_PAGINATION](data) {
    this.paginationUserList = data;
  }

  @Action
  [Actions.REQ_USER_LIST]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/user-list")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AGENT_LIST, data);
          this.context.commit(Mutations.SET_SETTORI_LIST, data.settori);
          this.context.commit(Mutations.SET_YEARS_LIST, data.years);
          resolve();
        })
        .catch(({ data:{data}  }) => {
          this.context.commit(Mutations.SET_USER_ERROR, data.error);
          reject();
        });
    });
  }

  @Action
  [Actions.DEL_AGENT](payload) {
    this.context.commit(Mutations.SET_AGENT_LIST, []);
  }
}