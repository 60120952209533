import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IRole } from "@/store/modules/divere/UserModule";

export interface PrimaryMenu{
    title: string;
    filterMenuRole:Array<IRole>;
    divereSecondaryMenus:Array<number>;	
}
export interface SecondaryMenu{
         title: string;
         filterMenuRole:Array<IRole>;
}


export interface IMenu{
    menuErrors: Array<string>;
    primaryMenu: Array<PrimaryMenu>;
}

@Module
export default class MenuModule extends VuexModule implements IMenu{

    menuErrors = [];
    primaryMenu = [];
    secondaryMenu = {} as SecondaryMenu;

  
    /**
     * Get  primary menu
     * @returns PrimaryMenu
     */
    get getPrimaryMenu(): Array<PrimaryMenu> {
      return this.primaryMenu;
    }
  
    /**
     * Get secondary menu
     * @returns SecondaryMenu
     */
     get getSecondaryMenu(): SecondaryMenu {
        return this.secondaryMenu;
    }
  
    /**
     * Get authentification errors
     * @returns array
     */
    get getMenuErrors(): Array<string> {
      return this.menuErrors;
    }

    @Action
    [Actions.REQ_PRIMARY_MENU]() {
      // ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
        ApiService.get("http://localhost:1337/divere-primary-menus" , "?_sort=index")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_PRIMARY_MENU, data);
            resolve();
          })
          .catch(({ error }) => {
            this.context.commit(Mutations.SET_ERROR, error);
            reject();
          });
      });
    }

    @Mutation
    [Mutations.SET_PRIMARY_MENU](primaryMenu) {
      this.primaryMenu = primaryMenu;
    }

    @Mutation
    [Mutations.SET_ERROR](error) {
      this.menuErrors = error;
    }

}