import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { errorMessage } from "@/core/services/AlertService";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/dashboard",
		component: () => import("@/layout/Layout.vue"),
		children: [
			{
				path: "/dashboard",
				name: "dashboard",
				component: () => import("@/views/divere/Dashboard.vue")
			},
			{
				path: "/builder",
				name: "builder",
				component: () => import("@/views/Builder.vue")
			},
			{
				path: "/pages/profile",
				name: "profile",
				component: () =>
					import("@/components/page-layouts/Profile.vue"),
				children: [
					{
						path: "overview",
						name: "profile-overview",
						component: () =>
							import("@/views/divere/pages/profile/Overview.vue")
					},
					{
						path: "projects",
						name: "profile-projects",
						component: () =>
							import("@/views/divere/pages/profile/Projects.vue")
					},
					{
						path: "campaigns",
						name: "profile-campaigns",
						component: () =>
							import(
								"@/views/divere/pages/profile/Campaigns.vue"
							)
					},
					{
						path: "documents",
						name: "profile-documents",
						component: () =>
							import(
								"@/views/divere/pages/profile/Documents.vue"
							)
					},
					{
						path: "connections",
						name: "profile-connections",
						component: () =>
							import(
								"@/views/divere/pages/profile/Connections.vue"
							)
					},
					{
						path: "activity",
						name: "profile-activity",
						component: () =>
							import("@/views/divere/pages/profile/Activity.vue")
					}
				]
			},
			{
				path: "/crafted/pages/wizards/horizontal",
				name: "horizontal-wizard",
				component: () =>
					import("@/views/divere/pages/wizards/Horizontal.vue")
			},
			{
				path: "/survey/:step",
				name: "survey-iii",
				component: () =>
					import("@/views/divere/pages/survey-iii/Survey.vue")
			},
			{
				path: "/survey-plus/report",
				name: "survey-plus-report",
				component: () =>
				import("@/views/divere/pages/survey-plus/Report.vue")
			},
			{
				path: "/survey-plus/:step",
				name: "survey-plus",
				component: () =>
					import("@/views/divere/pages/survey-plus/SurveyPlus.vue")
			},
			{
				path: "/report",
				name: "report",
				component: () =>
					import("@/views/divere/pages/report/Report.vue")
			},
			{
				path: "/survey-structure",
				name: "survey-structure",
				component: () =>
					import("@/views/divere/pages/survey-structure/SurveryStructure.vue")
			},
			{
				path: "/survey-plus-structure",
				name: "survey-plus-structure",
				component: () =>
					import("@/views/divere/pages/survey-plus-structure/SurveryPlusStructure.vue")
			},
			{
				path: "/survey-summary",
				name: "survey-summary",
				component: () =>
					import("@/views/divere/pages/survey-summary/SurverySummary.vue")
			},
			{
				path: "/pages/account",
				name: "account",
				component: () => import("@/views/divere/account/Account.vue"),
				children: [
					{
						path: "overview",
						name: "account-overview",
						component: () =>
							import("@/views/divere/account/Overview.vue")
					},
					{
						path: "settings",
						name: "account-settings",
						component: () =>
							import("@/views/divere/account/Settings.vue")
					},
					{
						path: "company-settings",
						name: "company-settings",
						component: () =>
							import(
								"@/views/divere/account/CompanySettings.vue"
							)
					}
				]
			},
			{
				path: "/apps/customers/getting-started",
				name: "apps-customers-getting-started",
				component: () =>
					import("@/views/divere/customers/GettingStarted.vue")
			},
			{
				path: "/apps/customers/customers-listing",
				name: "apps-customers-listing",
				component: () =>
					import("@/views/divere/agents/AgentListing.vue")
			},
			{
				path: "/apps/customers/customer-details",
				name: "apps-customers-details",
				component: () =>
					import("@/views/divere/customers/CustomerDetails.vue")
			},
			{
				path: "/apps/agents/getting-started",
				name: "apps-agents-getting-started",
				component: () =>
					import("@/views/divere/agents/GettingStarted.vue")
			},
			{
				path: "/apps/agents/agents-listing",
				name: "apps-agents-listing",
				component: () =>
					import("@/views/divere/agents/AgentListing.vue")
			},
			{
				path: "/apps/agents/agents-details",
				name: "apps-agents-details",
				component: () =>
					import("@/views/divere/agents/AgentDetails.vue")
			},
			{
				path: "/apps/calendar",
				name: "apps-calendar",
				component: () => import("@/views/divere/calendar/Calendar.vue")
			},
			{
				path: "/apps/chat/private-chat",
				name: "apps-private-chat",
				component: () => import("@/views/divere/chat/Chat.vue")
			},
			{
				path: "/apps/chat/group-chat",
				name: "apps-group-chat",
				component: () => import("@/views/divere/chat/Chat.vue")
			},
			{
				path: "/apps/chat/drawer-chat",
				name: "apps-drawer-chat",
				component: () => import("@/views/divere/chat/DrawerChat.vue")
			},
			{
				path: "/menu-tree/tree-listing",
				name: "menu-tree-listing",
				component: () =>
					import("@/views/divere/menu-tree/MenuTreeListing.vue")
			},
			{
				path: "/crafted/modals/general/invite-friends",
				name: "modals-general-invite-friends",
				component: () =>
					import("@/views/divere/modals/general/InviteFriends.vue")
			},
			{
				path: "/crafted/modals/general/view-user",
				name: "modals-general-view-user",
				component: () =>
					import("@/views/divere/modals/general/ViewUsers.vue")
			},
			{
				path: "/crafted/modals/general/upgrade-plan",
				name: "modals-general-upgrade-plan",
				component: () =>
					import("@/views/divere/modals/general/UpgradePlan.vue")
			},
			{
				path: "/crafted/modals/general/share-and-earn",
				name: "modals-general-share-and-earn",
				component: () =>
					import("@/views/divere/modals/general/ShareAndEarn.vue")
			},
			{
				path: "/crafted/modals/forms/new-target",
				name: "modals-forms-new-target",
				component: () =>
					import("@/views/divere/modals/forms/NewTarget.vue")
			},
			{
				path: "/crafted/modals/forms/new-card",
				name: "modals-forms-new-card",
				component: () =>
					import("@/views/divere/modals/forms/NewCard.vue")
			},
			{
				path: "/crafted/modals/forms/new-address",
				name: "modals-forms-new-address",
				component: () =>
					import("@/views/divere/modals/forms/NewAddress.vue")
			},
			{
				path: "/crafted/modals/forms/create-api-key",
				name: "modals-forms-create-api-key",
				component: () =>
					import("@/views/divere/modals/forms/CreateApiKey.vue")
			},
			{
				path: "/crafted/modals/wizards/two-factor-auth",
				name: "modals-wizards-two-factor-auth",
				component: () =>
					import("@/views/divere/modals/wizards/TwoFactorAuth.vue")
			},
			{
				path: "/crafted/modals/wizards/create-app",
				name: "modals-wizards-create-app",
				component: () =>
					import("@/views/divere/modals/wizards/CreateApp.vue")
			},
			{
				path: "/crafted/modals/wizards/create-account",
				name: "modals-wizards-create-account",
				component: () =>
					import("@/views/divere/modals/wizards/CreateAccount.vue")
			},
			{
				path: "/crafted/widgets/lists",
				name: "widgets-list",
				component: () => import("@/views/divere/widgets/Lists.vue")
			},
			{
				path: "/crafted/widgets/statistics",
				name: "widgets-statistics",
				component: () =>
					import("@/views/divere/widgets/Statistics.vue")
			},
			{
				path: "/crafted/widgets/charts",
				name: "widgets-charts",
				component: () => import("@/views/divere/widgets/Charts.vue")
			},
			{
				path: "/crafted/widgets/mixed",
				name: "widgets-mixed",
				component: () => import("@/views/divere/widgets/Mixed.vue")
			},
			{
				path: "/crafted/widgets/tables",
				name: "widgets-tables",
				component: () => import("@/views/divere/widgets/Tables.vue")
			},
			{
				path: "/crafted/widgets/feeds",
				name: "widgets-feeds",
				component: () => import("@/views/divere/widgets/Feeds.vue")
			}
		]
	},
	{
		path: "/admin",
		component: () => import("@/layout/Layout.vue"),
		meta: {adminOnly: true},
		children: [
			{
				path: "users",
				name: "AdminUserList",
				component: () =>
					import("@/views/divere/pages/user-list/UserList.vue"),
			},
			{
				path: ":id/dashboard",
				name: "AdminUserDashboard",
				component: () => import("@/views/divere/Dashboard.vue")
			},
			{
				path: "survey/:id/:step",
				name: "AdminSurvey",
				component: () =>
					import("@/views/divere/pages/survey-iii/Survey.vue")
			},
			{
				path: "report/:id",
				name: "admin-report",
				component: () =>
					import("@/views/divere/pages/report/Report.vue")
			},
			{
				path: "survey-plus/:id/:step",
				name: "AdminSurveyPlus",
				component: () =>
					import("@/views/divere/pages/survey-plus/SurveyPlus.vue")
			},
			{
				path: "survey-plus/report/:id",
				name: "admin-survey-plus-report",
				component: () =>
					import("@/views/divere/pages/survey-plus/Report.vue")
			},
			// Simulated User settings.
			{
				path: ":id/pages/account",
				name: "simulated-user-account",
				component: () => import("@/views/divere/account/SimulatedUserAccount.vue"),
				children: [
					{
						path: "overview",
						name: "simulated-user-account-overview",
						component: () =>
							import("@/views/divere/account/SimulatedUserOverview.vue")
					},
					{
						path: "settings",
						name: "simulated-user-account-settings",
						component: () =>
							import("@/views/divere/account/SimulatedUserSettings.vue")
					},
					{
						path: "company-settings",
						name: "simulated-user-company-settings",
						component: () =>
							import(
								"@/views/divere/account/SimulatedUserCompanySettings.vue"
							)
					}
				]
			},
		]
	},
	{
		path: "/",
		component: () => import("@/components/page-layouts-valored/Auth.vue"),
		children: [
			
			{
				path: "/sign-in",
				name: "sign-in",
				component: () =>
					import(
						"@/views/divere/authentication/basic-flow/SignIn.vue"
					)
			},
			{
				path: "/sign-up",
				name: "sign-up",
				component: () =>
					import(
						"@/views/divere/authentication/basic-flow/SignUp.vue"
					)
			},
			{
				path: "/register/verify/:user_id/:user_hash",
				name: "register-verify",
				component: () =>
					import(
						"@/views/divere/authentication/EmailVerification.vue"
					)
			},
			{
				path: "/password-reset-email",
				name: "password-reset-email",
				component: () =>
					import(
						"@/views/divere/authentication/basic-flow/PasswordResetEmail.vue"
					)
			},
			{
				path: "/password_reset/:user_id/:user_hash",
				name: "password-reset",
				component: () =>
					import(
						"@/views/divere/authentication/basic-flow/PasswordReset.vue"
					)
			}
		]
	},
	{
		path: "/landing",
		name: "landing",
		component: () =>
			import(
				"@/views/divere/landing/Landing.vue"
			)
	},
	{
		// the 404 route, when none of the above matches
		path: "/404",
		name: "404",
		component: () => import("@/views/divere/authentication/Error404.vue")
	},
	{
		path: "/500",
		name: "500",
		component: () => import("@/views/divere/authentication/Error500.vue")
	},
	{
		path: "/:pathMatch(.*)*",
		redirect: "/404"
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes
});

const isAdmin = () => { 
	return store.getters.isAdmin;
}

const isUserAuthenticated = () => { 
	return store.getters.isUserAuthenticated;
}

router.beforeEach((to, from, next) => {
	store.commit(Mutations.RESET_LAYOUT_CONFIG);
	store.dispatch(Actions.GET_GLOBAL_CONFIG);
	if (to.matched.some(record => record.meta.adminOnly)) {
		if (isAdmin()) {
			next();
		} else {
			errorMessage("You are not admin user.");
			next({ name: "dashboard" });
		}
	} else { 
		if(to.fullPath == "/sign-in" || to.fullPath == "/landing" || to.fullPath == "/sign-up"  || to.name == "password-reset-email" || to.name === "password-reset"
		|| to.fullPath.includes("register/verify") ) {
			if(isUserAuthenticated()) next({ name: "dashboard" });
			else next();
		}else {
			if(isUserAuthenticated()) next();
			else next({name:"landing"});
		}
	}

	// Scroll page to top on every route change
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
});

export default router;
