import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

export interface IReport {
  III: number;
  Governance: number;
  Attractiveness: number;
  Development: number;
  Retainment: number;
  Paygap_Bonus: number;
  counter?: number;
}

export interface IEmployeeRange {
  range_id: number;
  description: string;
  start_value: number;
  end_value: number;
}

export interface IFatturati {
  id: number; 
  descrizione: string;
}

export interface IGeneration {
  young: number;
  mid: number;
  old: number;
}

export  interface IGenerationMix {
  DONNE: IGeneration;
  UOMINI: IGeneration;
  labelUp: IGeneration;
  year: number;
}

export  interface IGenerationMixList {
  [year: number]: IGenerationMix;
}

export interface IGenerationMixTable {
  F1_DONNE_ENTRATE:number;
  F1_DONNE_USCITE:number;
  F1_UOMINI_ENTRATI:number;
  F1_UOMINI_USCITI:number;
  F2_DONNE_ENTRATE:number;
  F2_DONNE_USCITE:number;
  F2_UOMINI_ENTRATI:number;
  F2_UOMINI_USCITI:number;
  F3_DONNE_ENTRATE:number;
  F3_DONNE_USCITE:number;
  F3_UOMINI_ENTRATI:number;
  F3_UOMINI_USCITI:number;
  USCENTI_F1_DONNE:number;
  USCENTI_F1_UOMINI:number;
  USCENTI_F2_DONNE:number;
  USCENTI_F2_UOMINI:number;
  USCENTI_F3_DONNE:number;
  USCENTI_F3_UOMINI:number;
  d_6_2:number;
  d_6_3:number;
  d_6_4:number;
  u_6_2:number;
  u_6_3:number;
  u_6_4:number;
  val_4:string;
  val_5:string;
  val_6:string;
  val_7:string;
  val_8:string;
  val_9:string;
  year:number;
}

export interface IGenerationMixTableList {
  [year: number]: IGenerationMixTable;

}

export interface ISettori {
  id: number; 
  descrizione: string;
  descrizione_en: string;
  directors_segregation_type: string;
  employees_segregation_type: string;
  managers_segregation_type: string;
  workers_segregation_type: string;
  directors_correction_coefficient: number;
  employees_correction_coefficient: number;
  managers_correction_coefficient: number;
  workers_correction_coefficient: number;
}

export interface ISelectOptionList {
  employee_range : IEmployeeRange;
  fatturati: IFatturati;
  settori: ISettori;
}

export interface IReportList {
  [year: number]: IReport;
}

export interface IReportModule {
  avgReport: IReport | null;
  avgReportList: IReportList | null;
  reportLists : IReportList | null;
  selectOptionLists : ISelectOptionList | null;
  compareLists: IReportList | null;
}



@Module
export default class ReportModule extends VuexModule implements IReportModule {

  avgReport = null as IReport | null;
  avgReportList = null as IReportList | null;
  reportLists = null as IReportList | null;
  selectOptionLists = null as ISelectOptionList | null;
  compareLists = null as IReportList | null;
  generationMixList = null as IGenerationMixList | null;
  generationMixTableList = null as IGenerationMixTableList | null;

  /**
  * Get current year report data
  * @returns IReport
  */
  get getAverageReport(): IReport | null  {
    return this.avgReport;
  }

  /**
  * Get current year report data
  * @returns IReportList
  */
  get getAverageReportList(): IReportList | null  {
    return this.avgReportList;
  }


  /**
  * Get Report lists
  * @returns IReport
  */
  get getReportLists(): IReportList | null {
    return this.reportLists;
  }

    /**
  * Get Select Options lists
  * @returns IReport
  */
  get getSelectOptionLists(): ISelectOptionList | null {
    return this.selectOptionLists;
  }
  
  /**
  * Get Compare lists
  * @returns IReport
  */
   get getCompareLists(): IReportList | null {
    return this.compareLists;
  }

  /**
  * Get GenerationMixList
  * @returns IGenerationMixList
  */
  get getGenerationMixList(): IGenerationMixList | null {
    return this.generationMixList;
  }

  /**
  * Get GenerationMixTableList
  * @returns IGenerationMixTableList
  */
  get getGenerationMixTableList(): IGenerationMixTableList | null {
    return this.generationMixTableList;
  }

  @Mutation
  [Mutations.SET_AVERAGE_REPORT](data: IReport) {
    this.avgReport = data;
  }

  @Mutation
  [Mutations.SET_AVERAGE_REPORT_LIST](data: IReportList) {
    this.avgReportList = data;
  }

  @Mutation
  [Mutations.SET_DETAIL_REPORT](data: IReportList) {
    this.reportLists = data;
  }

  @Mutation
  [Mutations.SET_COMPARE_SELECT_OTPIONS](data: ISelectOptionList) {
    this.selectOptionLists = data;
  }

  @Mutation
  [Mutations.SET_COMPARE_REPORT](data: IReportList) {
    this.compareLists = data;
  }

  @Mutation
  [Mutations.SET_GENERATION_MIX_REPORT](data: IGenerationMixList) {
    this.generationMixList = data;
  }

  @Mutation
  [Mutations.SET_GENERATION_MIX_TABLE](data: IGenerationMixTableList) {
    this.generationMixTableList = data;
  }

  @Action
  [Actions.REQ_AVERAGE_REPORT](id_year) {
    const year =
      id_year !== "" && id_year !== undefined
        ? id_year
        : store.getters.getCompanyYearList.current_yearly_period;
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("report/avg/", year)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AVERAGE_REPORT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_AVERAGE_REPORT_LIST](user_id = "") {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`report/avg-all/${user_id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AVERAGE_REPORT_LIST, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_DETAIL_REPORT](user_id = "") {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`report/${user_id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_DETAIL_REPORT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }


  @Action
  [Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS] () {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get("select-option")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPARE_SELECT_OTPIONS, data.response);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_COMPARE_REPORT](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post(`report/compare/${payload.user_id}`, payload.data)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_COMPARE_REPORT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_GENERATION_MIX_REPORT](payload) {
    const user_id = payload.user_id ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`generation-mix/compare/${user_id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERATION_MIX_REPORT, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }

  @Action
  [Actions.REQ_GENERATION_MIX_TABLE](payload) {
    const user_id = payload.user_id ? payload.user_id : "";
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.get(`generation-mix/table/${user_id}`)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_GENERATION_MIX_TABLE, data.data);
          resolve();
        })
        .catch(({ data: { data } }) => {
          console.error(data);
          reject();
        });
    });
  }
}
