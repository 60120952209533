import Swal from "sweetalert2/dist/sweetalert2.min.js";

export const errorMessage = (text: string | null, confirmButtonText = "Try again!") => {
    return Swal.fire({
        text: text,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: confirmButtonText,
        customClass: {
            confirmButton: "btn fw-bold btn-dark-blue"
        }
    });
}

export const successMessage = (text: string | null) => {
    return Swal.fire({
        text: text,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
            confirmButton: "btn fw-bold btn-dark-blue"
        }
    });
}

export default {
    errorMessage,
};